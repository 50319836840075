import React from 'react'
import { withRouter } from 'react-router'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Bar } from 'react-chartjs-2'
import { Tab, TabPanel, Tabs, TabList } from 'react-web-tabs'

var monthName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

class TesterPage extends React.Component {
  constructor(props) {
    super(props)
    // const currentUser = UserId();
    this.state = {
      profileAndPerfDataByTester: {},
      qualitydetails: {},
      productivity: {},
      pkt: {},
      startDate: new Date(),
      endDate: new Date(),
      performance: [],
      mismatches: [],
      hmd: [],
      sortType: 'asc',
      testerName: '',
      chartData: {},
      alias: '',
      fullName: '',
      currentRole: '',
      totalTenure: '',
      jobLevel: '',
      teamName: '',
      previousRole: '',
      prevRoleTenure: '',
      curRoleTenure: '',
      doj_yr: '',
      doj_mm: '',
      availableDate_tester: {},
      teamAvgDataLevelWise: {},
      avgPerformance: [],
    }
  }

  componentDidMount() {
    this.availableDateRange()
    this.teamAverageDataLevelWise()
    const testerName = this.props.match.params.testerName
    const { availableDate_tester, teamAvgDataLevelWise, avgPerformance } =
      this.state
    console.log(
      'Month Range for Tester inside componentDidmount',
      availableDate_tester
    )
    console.log('Team Average Data Level Wise', teamAvgDataLevelWise)
    console.log('Team Average Performance data ', avgPerformance)

    axios
      .get(
        process.env.REACT_APP_PROFILER_BACKEND +
          'ProfileAndPerformanceDatabyTester/' +
          testerName
      )
      .then((res) => {
        let min, max
        if (Object.keys(res.data.productivity).length > 0) {
          min = Math.min.apply(null, Object.keys(res.data.productivity))
          max = Math.max.apply(null, Object.keys(res.data.productivity))
        } else {
          min = Date.now()
          max = Date.now()
        }

        let performance_arr = Object.keys(res.data.productivity).map((item) => {
          return {
            month: item,
            pkt: res.data.pkt[item],
            productivity: res.data.productivity[item],
            quality_percentage: res.data.qualitydetails[item]
              ? res.data.qualitydetails[item].quality_percentage
              : null,
          }
        })

        let chart1 = []
        chart1 = Object.values(this.state.availableDate_tester)
        console.log('chart1', chart1)
        let chart = {
          labels: chart1,
          labelString: 'TEST',
          datasets: [
            {
              label: 'productivity',
              data:
                this.state.availableDate_tester &&
                Object.keys(this.state.availableDate_tester).map((item) => {
                  return res.data.productivity[item]
                    ? res.data.productivity[item].toFixed(2)
                    : 0
                }),

              backgroundColor: 'rgba(54,162,235,0.6)',
            },
            {
              label: 'quality',
              data:
                this.state.availableDate_tester &&
                Object.keys(this.state.availableDate_tester).map((item) => {
                  return res.data.qualitydetails[item]
                    ? res.data.qualitydetails[item].quality_percentage.toFixed(
                        2
                      )
                    : 0
                }),
              backgroundColor: 'rgba(75,192,192,0.4)',
            },
            {
              label: 'pkt',
              data:
                this.state.availableDate_tester &&
                Object.keys(this.state.availableDate_tester).map((item) => {
                  return res.data.pkt[item]
                }),
              backgroundColor: 'rgba(255,99,132,0.6)',
            },
          ],
        }
        let mismatches_arr = []
        res.data.mismatches &&
          Object.keys(res.data.mismatches).forEach((item) => {
            let mismatches_temp = Object.keys(res.data.mismatches[item]).map(
              (subItem) => {
                return {
                  month: item,
                  tester: res.data.mismatches[item][subItem].testerName,
                  auditKey: res.data.mismatches[item][subItem].auditKey,
                  auditType: res.data.mismatches[item][subItem].auditType,
                  mismatchType: res.data.mismatches[item][subItem].mismatchType,
                }
              }
            )
            mismatches_arr = mismatches_arr.concat(mismatches_temp)
          })
        let hmd_arr = []
        res.data.hmd &&
          Object.keys(res.data.hmd).forEach((item) => {
            let hmd_temp = Object.keys(res.data.hmd[item]).map((subItem) => {
              return {
                month: item,
                tester: res.data.hmd[item][subItem].testerName,
                detractor: res.data.hmd[item][subItem].detractor,
                appid: res.data.hmd[item][subItem].appid.split('|')[0],
                skillid: res.data.hmd[item][subItem].appid.split('|')[1],
              }
            })
            hmd_arr = hmd_arr.concat(hmd_temp)
          })

        this.setState({
          profileAndPerfDataByTester: res.data,
          performance: performance_arr,
          mismatches: mismatches_arr,
          hmd: hmd_arr,
          chartData: chart,
          startDate: new Date(min),
          endDate: new Date(max),
          teamName: res.data.team,
          alias: res.data.name,
          fullName: res.data.fullName,
          currentRole: res.data.currentRole,
          previousRole: res.data.previousRole ? res.data.previousRole : 'NA',
          prevRoleTenure: res.data.prevRoleTenure
            ? res.data.prevRoleTenure
            : 'NA',
          curRoleTenure: res.data.curRoleTenure ? res.data.curRoleTenure : 'NA',
          jobLevel: res.data.joblevel,
          doj_yr: res.data.doj[0],
          doj_mm: res.data.doj[1],
          doj_dd: res.data.doj[2],
          totalTenure: res.data.totalenure ? res.data.totalenure : 'NA',
        })
      })
    // this.currentTesterDetail()
  }

  availableDateRange() {
    const testerName = this.props.match.params.testerName
    axios
      .get(
        process.env.REACT_APP_PROFILER_BACKEND +
          'availableDateRangeforTester/' +
          testerName
      )
      .then((res) => {
        this.setState({
          availableDate_tester: res.data,
        })
      })
  }

  teamAverageDataLevelWise() {
    const testerName = this.props.match.params.testerName
    axios
      .get(
        process.env.REACT_APP_PROFILER_BACKEND +
          'MonthlyAverageForTesters/' +
          testerName
      )
      .then((res) => {
        let min, max
        if (Object.keys(res.data.avgTeamProductivity).length > 0) {
          min = Math.min.apply(null, Object.keys(res.data.avgTeamProductivity))
          max = Math.max.apply(null, Object.keys(res.data.avgTeamProductivity))
        } else {
          min = Date.now()
          max = Date.now()
        }
        let avgTeamPerformance_arr = Object.keys(
          res.data.avgTeamProductivity
        ).map((item) => {
          return {
            month: item,
            avgProduductivity: res.data.avgTeamProductivity[item],
            avgPkt: res.data.avgTeamPkt[item]
              ? res.data.avgTeamPkt[item]
              : null,
            avg_quality_percentage: res.data.avgTeamQuality[item]
              ? res.data.avgTeamQuality[item]
              : null,
          }
        })

        this.setState({
          teamAvgDataLevelWise: res.data,
          avgPerformance: avgTeamPerformance_arr,
          startDate: new Date(min),
          endDate: new Date(max),
        })
      })
  }

  // currentTesterDetail() {
  //     const testerName = this.props.match.params.testerName
  //     axios
  //         .get(
  //             "https://localhost:8443/CurrentUser/" + testerName
  //         )
  //         .then((res) => {
  //             this.setState({
  //                 alias: res.data.name,
  //                 fullName: res.data.fullName,
  //                 currentRole: res.data.currentRole,
  //                 totalTenure: res.data.totalTenure,
  //                 jobLevel: res.data.jobLevel,
  //                 doj: res.data.doj
  //             });
  //         });
  // }

  handleDateChange = (e) => {
    const testerName = this.props.match.params.testerName
    const {
      startDate,
      endDate,
      availableDate_tester,
      teamAvgDataLevelWise,
      avgPerformance,
    } = this.state

    let monthRange = []
    monthRange = Object.values(availableDate_tester)
    let selectedStartDate = monthName[e.getMonth()] + '-' + e.getFullYear()
    let max, min
    if (Object.keys(availableDate_tester).length > 0) {
      max =
        monthName[
          new Date(
            Math.max.apply(null, Object.keys(availableDate_tester))
          ).getMonth()
        ] +
        '-' +
        new Date(
          Math.max.apply(null, Object.keys(availableDate_tester))
        ).getFullYear()
      min =
        monthName[
          new Date(
            Math.min.apply(null, Object.keys(availableDate_tester))
          ).getMonth()
        ] +
        '-' +
        new Date(
          Math.min.apply(null, Object.keys(availableDate_tester))
        ).getFullYear()
    } else {
      max = Date.now()
      min = Date.now()
    }

    console.log('Maximum month', max)
    console.log('Minimum month', min)

    let newStartDate, newEndDate
    if (monthRange.includes(selectedStartDate)) {
      newStartDate = selectedStartDate
      newEndDate = monthName[endDate.getMonth()] + '-' + endDate.getFullYear()
    } else {
      newEndDate = max
      newStartDate = min
    }

    console.log('Date Change in Onchnage', newStartDate)
    axios
      .all([
        axios.get(
          process.env.REACT_APP_PROFILER_BACKEND +
            'ProfileAndPerformanceDatabyTester/' +
            testerName +
            '/' +
            newStartDate +
            '/' +
            newEndDate
        ),
        axios.get(
          process.env.REACT_APP_PROFILER_BACKEND +
            'MonthlyAverageForTesters/' +
            testerName +
            '/' +
            newStartDate +
            '/' +
            newEndDate
        ),
      ])
      .then(
        axios.spread((res, res1) => {
          let chart1 = []
          chart1 = Object.values(this.state.availableDate_tester)
          console.log('chart1', chart1)

          let chart = {
            labels: chart1,
            labelString: 'TEST',
            datasets: [
              {
                label: 'productivity',
                data:
                  this.state.availableDate_tester &&
                  Object.keys(this.state.availableDate_tester).map((item) => {
                    return res.data.productivity[item]
                      ? res.data.productivity[item].toFixed(2)
                      : 0
                  }),
                backgroundColor: 'rgba(54,162,235,0.6)',
              },
              {
                label: 'quality',
                data:
                  this.state.availableDate_tester &&
                  Object.keys(this.state.availableDate_tester).map((item) => {
                    return res.data.qualitydetails[item]
                      ? res.data.qualitydetails[
                          item
                        ].quality_percentage.toFixed(2)
                      : 0
                  }),
                backgroundColor: 'rgba(75,192,192,0.4)',
              },
              {
                label: 'pkt',
                data:
                  this.state.availableDate_tester &&
                  Object.keys(this.state.availableDate_tester).map((item) => {
                    return res.data.pkt[item]
                  }),
                backgroundColor: 'rgba(255,99,132,0.6)',
              },
            ],
          }

          let performance_arr =
            res.data.productivity &&
            Object.keys(res.data.productivity).map((item) => {
              return {
                month: item,
                pkt: res.data.pkt[item],
                productivity: res.data.productivity[item],
                quality_percentage: res.data.qualitydetails[item]
                  ? res.data.qualitydetails[item].quality_percentage
                  : null,
              }
            })
          let mismatches_arr = []
          res.data.mismatches &&
            Object.keys(res.data.mismatches).forEach((item) => {
              let mismatches_temp = Object.keys(res.data.mismatches[item]).map(
                (subItem) => {
                  return {
                    month: item,
                    tester: res.data.mismatches[item][subItem].testerName,
                    auditKey: res.data.mismatches[item][subItem].auditKey,
                    auditType: res.data.mismatches[item][subItem].auditType,
                    mismatchType:
                      res.data.mismatches[item][subItem].mismatchType,
                  }
                }
              )
              mismatches_arr = mismatches_arr.concat(mismatches_temp)
            })
          let hmd_arr = []
          res.data.hmd &&
            Object.keys(res.data.hmd).forEach((item) => {
              let hmd_temp = Object.keys(res.data.hmd[item]).map((subItem) => {
                return {
                  month: item,
                  tester: res.data.hmd[item][subItem].testerName,
                  detractor: res.data.hmd[item][subItem].detractor,
                  appid: res.data.hmd[item][subItem].appid.split('|')[0],
                  skillid: res.data.hmd[item][subItem].appid.split('|')[1],
                }
              })
              hmd_arr = hmd_arr.concat(hmd_temp)
            })

          let avgTeamPerformance_arr = Object.keys(
            res1.data.avgTeamProductivity
          ).map((item) => {
            return {
              month: item,
              avgProduductivity: res1.data.avgTeamProductivity[item],
              avgPkt: res1.data.avgTeamPkt[item]
                ? res1.data.avgTeamPkt[item]
                : null,
              avg_quality_percentage: res1.data.avgTeamQuality[item]
                ? res1.data.avgTeamQuality[item]
                : null,
            }
          })

          this.setState({
            profileAndPerfDataByTester: res.data,
            performance: performance_arr,
            mismatches: mismatches_arr,
            hmd: hmd_arr,
            chartData: chart,
            teamAvgDataLevelWise: res.data,
            avgPerformance: avgTeamPerformance_arr,
            startDate: new Date(newStartDate),
            endDate: new Date(newEndDate),
          })
          console.log('res', res, 'res1', res1)
        })
      )
  }

  handleEndDateChange = (e) => {
    const testerName = this.props.match.params.testerName
    const {
      startDate,
      endDate,
      availableDate_tester,
      teamAvgDataLevelWise,
      avgPerformance,
    } = this.state
    let monthRange = []
    monthRange = Object.values(availableDate_tester)
    let selectedEndDate = monthName[e.getMonth()] + '-' + e.getFullYear()

    let max, min
    if (Object.keys(availableDate_tester).length > 0) {
      max =
        monthName[
          new Date(
            Math.max.apply(null, Object.keys(availableDate_tester))
          ).getMonth()
        ] +
        '-' +
        new Date(
          Math.max.apply(null, Object.keys(availableDate_tester))
        ).getFullYear()
      min =
        monthName[
          new Date(
            Math.min.apply(null, Object.keys(availableDate_tester))
          ).getMonth()
        ] +
        '-' +
        new Date(
          Math.min.apply(null, Object.keys(availableDate_tester))
        ).getFullYear()
    } else {
      max = Date.now()
      min = Date.now()
    }

    console.log('Maximum month', max)
    console.log('Minimum month', min)
    let newStartDate, newEndDate

    if (monthRange.includes(selectedEndDate)) {
      newEndDate = selectedEndDate
      newStartDate =
        monthName[startDate.getMonth()] + '-' + startDate.getFullYear()
    } else {
      newEndDate = max
      newStartDate = min
    }
    console.log('Start Date inside End Date onChnage', startDate)
    console.log('New Start Date and New End Date', newStartDate, newEndDate)

    axios
      .all([
        axios.get(
          process.env.REACT_APP_PROFILER_BACKEND +
            'ProfileAndPerformanceDatabyTester/' +
            testerName +
            '/' +
            newStartDate +
            '/' +
            newEndDate
        ),
        axios.get(
          process.env.REACT_APP_PROFILER_BACKEND +
            'MonthlyAverageForTesters/' +
            testerName +
            '/' +
            newStartDate +
            '/' +
            newEndDate
        ),
      ])
      .then(
        axios.spread((res, res1) => {
          let chart1 = []
          chart1 = Object.values(this.state.availableDate_tester)
          console.log('chart1', chart1)

          let chart = {
            labels: chart1,
            labelString: 'TEST',
            datasets: [
              {
                label: 'productivity',
                data: Object.keys(this.state.availableDate_tester).map(
                  (item) => {
                    return res.data.productivity[item]
                      ? res.data.productivity[item].toFixed(2)
                      : 0
                  }
                ),
                backgroundColor: 'rgba(54,162,235,0.6)',
              },
              {
                label: 'quality',
                data: Object.keys(this.state.availableDate_tester).map(
                  (item) => {
                    return res.data.qualitydetails[item]
                      ? res.data.qualitydetails[
                          item
                        ].quality_percentage.toFixed(2)
                      : 0
                  }
                ),
                backgroundColor: 'rgba(75,192,192,0.4)',
              },
              {
                label: 'pkt',
                data: Object.keys(this.state.availableDate_tester).map(
                  (item) => {
                    return res.data.pkt[item]
                  }
                ),
                backgroundColor: 'rgba(255,99,132,0.6)',
              },
            ],
          }

          let performance_arr = Object.keys(res.data.productivity).map(
            (item) => {
              return {
                month: item,
                pkt: res.data.pkt[item],
                productivity: res.data.productivity[item],
                quality_percentage: res.data.qualitydetails[item]
                  ? res.data.qualitydetails[item].quality_percentage
                  : null,
              }
            }
          )
          let mismatches_arr = []
          Object.keys(res.data.mismatches).forEach((item) => {
            let mismatches_temp = Object.keys(res.data.mismatches[item]).map(
              (subItem) => {
                return {
                  month: item,
                  tester: res.data.mismatches[item][subItem].testerName,
                  auditKey: res.data.mismatches[item][subItem].auditKey,
                  auditType: res.data.mismatches[item][subItem].auditType,
                  mismatchType: res.data.mismatches[item][subItem].mismatchType,
                }
              }
            )
            mismatches_arr = mismatches_arr.concat(mismatches_temp)
          })
          let hmd_arr = []
          Object.keys(res.data.hmd).forEach((item) => {
            let hmd_temp = Object.keys(res.data.hmd[item]).map((subItem) => {
              return {
                month: item,
                tester: res.data.hmd[item][subItem].testerName,
                detractor: res.data.hmd[item][subItem].detractor,
                appid: res.data.hmd[item][subItem].appid.split('|')[0],
                skillid: res.data.hmd[item][subItem].appid.split('|')[1],
              }
            })
            hmd_arr = hmd_arr.concat(hmd_temp)
          })

          let avgTeamPerformance_arr = Object.keys(
            res1.data.avgTeamProductivity
          ).map((item) => {
            return {
              month: item,
              avgProduductivity: res1.data.avgTeamProductivity[item],
              avgPkt: res1.data.avgTeamPkt[item]
                ? res1.data.avgTeamPkt[item]
                : null,
              avg_quality_percentage: res1.data.avgTeamQuality[item]
                ? res1.data.avgTeamQuality[item]
                : null,
            }
          })

          this.setState({
            profileAndPerfDataByTester: res.data,
            performance: performance_arr,
            mismatches: mismatches_arr,
            hmd: hmd_arr,
            chartData: chart,
            teamAvgDataLevelWise: res.data,
            avgPerformance: avgTeamPerformance_arr,
            startDate: new Date(newStartDate),
            endDate: new Date(newEndDate),
          })
          console.log('res', res, 'res1', res1)
        })
      )
  }

  render() {
    const testerName = this.props.match.params.testerName

    const {
      availableDate_tester,
      profileAndPerfDataByTester,
      performance,
      mismatches,
      hmd,
      sortType,
      alias,
      fullName,
      teamName,
      currentRole,
      doj_mm,
      doj_yr,
      doj_dd,
      totalTenure,
      jobLevel,
      curRoleTenure,
      previousRole,
      prevRoleTenure,
      startDate,
      endDate,
      totalenure,
      teamAvgDataLevelWise,
      avgPerformance,
    } = this.state
    let entries = Object.entries(profileAndPerfDataByTester)
    let size = Object.entries(profileAndPerfDataByTester).length
    console.log('Object data', entries)
    // let qualitydetails = profileAndPerfDataByTester.qualitydetails;
    console.log(profileAndPerfDataByTester)
    console.log('Performance', performance)
    console.log('Mismatches', mismatches)
    console.log('HMD', hmd)
    console.log('Available Date range for Tester', availableDate_tester)
    console.log('Team Average Data Level Wise', teamAvgDataLevelWise)
    console.log('Team Average Performance data ', avgPerformance)
    // console.log("DOJ", doj)
    const sortedPerformance = performance.sort((a, b) => {
      // debugger
      const isReserved = sortType === 'asc' ? 1 : -1
      return isReserved * a.month.localeCompare(b.month)
    })

    const sortedAvgPerformance = avgPerformance.sort((a, b) => {
      // debugger
      const isReserved = sortType === 'asc' ? 1 : -1
      return isReserved * a.month.localeCompare(b.month)
    })

    const sortedMisMatch = mismatches.sort((a, b) => {
      // debugger
      const isReserved = sortType === 'asc' ? 1 : -1
      return isReserved * a.month.localeCompare(b.month)
    })
    const sortedHMD = hmd.sort((a, b) => {
      // debugger
      const isReserved = sortType === 'asc' ? 1 : -1
      return isReserved * a.month.localeCompare(b.month)
    })
    return (
      <div>
        <div className="button headerInline headerMargin textSizeHeader">
          <button className="link-align disable-styling button-styling">
            Start-Date
            <br></br>
            <DatePicker
              value={this.state.startDate}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              onChange={this.handleDateChange}
              selected={this.state.startDate}
              minDate={new Date(2020, 0, 1)}
              maxDate={new Date()}
            ></DatePicker>
          </button>
          <button className="link-align disable-styling button-styling">
            End-Date
            <br></br>
            <DatePicker
              value={this.state.endDate}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              onChange={this.handleEndDateChange}
              selected={this.state.endDate}
              minDate={new Date(2020, 0, 1)}
              maxDate={new Date()}
            ></DatePicker>
          </button>
          <button
            class="link-align  disable-styling button-styling"
            onClick={() => this.props.history.goBack()}
          >
            <i class="fa fa-chevron-left fa-lg" aria-hidden="true"></i>
          </button>
          {/* <button
                                        className="link-align disable-styling button-styling"
                                        onClick={this.onClick(this.state.startDate, this.state.endDate)}
                                    >
                                        Apply
                                        </button> */}
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        {/* <div class="row center-align">
                    <div class="col s12 m4 l3 center ">
                        <div class="card light-blue bg-info text-white darken-4">
                            <div class="card-content white-text">
                                <div class="card-header">
                                    <h6 align="center"><b>Name: </b>{fullName}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m4 l3 center ">
                        <div class="card light-blue bg-secondary text-white darken-4">
                            <div class="card-content white-text">
                                <div class="card-header">
                                    <h6 align="center"><b>Alias: </b>{alias}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row center-align">
                    <div class="col s12 m4 l3 center ">
                        <div class="card light-blue bg-light text-dark darken-4">
                            <div class="card-content white-text">
                                <div class="card-header">
                                    <h6 align="center"><b>Current Job Role: </b>{currentRole}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m4 l3 center ">
                        <div class="card light-blue bg-light text-dark darken-4">
                            <div class="card-content white-text">
                                <div class="card-header">

                                    <h6 align="center"><b>Current Role Tenure: </b>{totalTenure}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row center-align">
                    <div class="col s12 m4 l3 center ">
                        <div class="card light-blue bg-secondary text-white darken-4">
                            <div class="card-content white-text">
                                <div class="card-header">
                                    <h6 align="center"><b>Job Level: </b>{jobLevel}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m4 l3 center ">
                        <div class="card light-blue bg-secondary text-white darken-4">
                            <div class="card-content white-text">
                                <div class="card-header">
                                    <h6 align="center"><b>Date of Joining: </b>{doj}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

        <div class="row center-align">
          <div class="col s12 m4 l3 center ">
            <div class="card light-blue darken-4">
              <div class="card-content white-text">
                <div class="card-header">
                  <h3 align="left">
                    <b>Tester Details</b>
                  </h3>
                </div>
                <div class="card-body">
                  <h6 class="card-subtitle mb-1" align="left" width="200px">
                    <b>Name: </b>
                    {fullName}
                  </h6>
                  <hr></hr>
                  <h6 class="card-subtitle mb-1" align="left" width="200px">
                    <b>Alias: </b>
                    {alias}
                  </h6>
                  <hr></hr>
                  <h6 class="card-subtitle mb-1" align="left" width="200px">
                    <b>Job Level: </b>
                    {jobLevel}
                  </h6>
                  <hr></hr>
                  <h6 class="card-subtitle mb-1" align="left" width="200px">
                    <b>Team Name: </b>
                    {teamName}
                  </h6>
                  <hr></hr>
                  <h6 class="card-subtitle mb-1" align="left" width="200px">
                    <b>Date of Joining: </b>
                    {doj_yr + '-' + doj_mm + '-' + doj_dd}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m4 l3 center ">
            <div class="card light-blue darken-4">
              <div class="card-content white-text">
                <div class="card-header">
                  <h3 align="left">
                    <b>Job Details</b>
                  </h3>
                </div>
                <div class="card-body">
                  <h6 class="card-subtitle mb-1" align="left" width="200px">
                    <b>Current Role: </b>
                    {currentRole}
                  </h6>
                  <hr></hr>
                  <h6 class="card-subtitle mb-1" align="left" width="200px">
                    <b>Current Role Total Tenure: </b>
                    {curRoleTenure}
                  </h6>
                  <hr></hr>
                  <h6 class="card-subtitle mb-1" align="left" width="200px">
                    <b>Previous Role: </b>
                    {previousRole}
                  </h6>
                  <hr></hr>
                  <h6 class="card-subtitle mb-1" align="left" width="200px">
                    <b>Previous Role Total Tenure: </b>
                    {prevRoleTenure}
                  </h6>
                  <hr></hr>
                  <h6 class="card-subtitle mb-1" align="left" width="200px">
                    <b>Total Tenure: </b>
                    {totalTenure}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container">
          {/* <div className="py-4"> */}
          &nbsp;&nbsp;
          <div className="chart">
            <Bar
              data={this.state.chartData}
              width={600}
              height={250}
              options={{
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        display: false,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
                // height: "300px",
                // width: "1190px",
                maintainAspectRatio: false,

                title: {
                  display: true,
                  text:
                    'Performance Data For ' +
                    monthName[startDate.getMonth()] +
                    '-' +
                    startDate.getFullYear() +
                    ' to ' +
                    monthName[endDate.getMonth()] +
                    '-' +
                    endDate.getFullYear(),
                  fontSize: 25,
                },
                legend: {
                  display: true,
                  position: 'right',
                },
              }}
            />
          </div>
          <Tabs>
            <TabList>
              <Tab
                tabFor="horizental-tab-one"
                className="addNewStyling textSizeHeader"
              >
                Tester Performance
              </Tab>
              <Tab
                tabFor="horizental-tab-two"
                className="addNewStyling textSizeHeader"
              >
                Team Average Data
              </Tab>
            </TabList>
            <TabPanel tabId="horizental-tab-one">
              <div>
                <div className="py-4">
                  <h3>Performance</h3>
                  <div class="col-sm-3 col-md-3 pull-right"></div>
                  <table className="table w3-hoverable">
                    <thead>
                      <tr>
                        <th>Month</th>
                        <th>Productivity</th>
                        <th>Quality</th>
                        <th>PKT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedPerformance &&
                        Object.keys(sortedPerformance).map((item) => (
                          <tr align="center">
                            <td align="left">
                              {monthName[
                                new Date(
                                  parseInt(sortedPerformance[item].month, 10)
                                ).getMonth()
                              ] +
                                '-' +
                                new Date(
                                  parseInt(sortedPerformance[item].month, 10)
                                ).getFullYear()}
                            </td>
                            <td align="left">
                              {sortedPerformance[item].productivity
                                ? sortedPerformance[item].productivity.toFixed(
                                    2
                                  )
                                : '-'}
                            </td>
                            <td align="left">
                              {sortedPerformance[item].quality_percentage
                                ? sortedPerformance[
                                    item
                                  ].quality_percentage.toFixed(2)
                                : '-'}
                            </td>
                            <td align="left">
                              {sortedPerformance[item].pkt
                                ? sortedPerformance[item].pkt
                                : '-'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="horizental-tab-two">
              <div>
                <div className="py-4">
                  <h3>Average Data</h3>
                  <div class="col-sm-3 col-md-3 pull-right"></div>
                  <table className="table w3-hoverable">
                    <thead>
                      <tr>
                        <th>Month</th>
                        <th>Avg-Productivity</th>
                        <th>Avg-Quality</th>
                        <th>Avg-PKT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {avgPerformance &&
                        Object.keys(avgPerformance).map((item) => (
                          <tr align="center">
                            {/* <td align="left">{item}</td> */}
                            <td align="left">
                              {monthName[
                                new Date(
                                  parseInt(avgPerformance[item].month, 10)
                                ).getMonth()
                              ] +
                                '-' +
                                new Date(
                                  parseInt(avgPerformance[item].month, 10)
                                ).getFullYear()}
                            </td>
                            <td align="left">
                              {avgPerformance[item].avgProduductivity
                                ? avgPerformance[
                                    item
                                  ].avgProduductivity.toFixed(2)
                                : '-'}
                            </td>
                            <td align="left">
                              {avgPerformance[item].avg_quality_percentage
                                ? avgPerformance[
                                    item
                                  ].avg_quality_percentage.toFixed(2)
                                : '-'}
                            </td>
                            <td align="left">
                              {avgPerformance[item].avgPkt
                                ? avgPerformance[item].avgPkt.toFixed(2)
                                : '-'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
          </Tabs>
          {/* </TabPanel> */}
        </div>
        <div className="container">
          <div className="py-4">
            <h3>Tester Mismatches Details</h3>
            <div class="col-sm-3 col-md-3 pull-right">
              <link
                href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                target="_blank"
                rel="stylesheet"
              />
              {/* <div className="search-box-container search-box"> */}

              {/* </div> */}
            </div>
            {/* <TableScrollbar rows={5}> */}
            <table className="table w3-hoverable">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Audit Key</th>
                  <th>Audit Type</th>
                  <th>Mismatch Type</th>
                </tr>
              </thead>
              <tbody>
                {
                  // profileAndPerfDataByTester.mismatches && Object.keys(profileAndPerfDataByTester.mismatches).map((item) => (

                  //     profileAndPerfDataByTester.mismatches[item].map((key) => (
                  // console.log(key)
                  sortedMisMatch &&
                    Object.keys(sortedMisMatch).map((item) => (
                      <tr align="center">
                        {/* <td align="left">{item}</td> */}
                        <td align="left">
                          {monthName[
                            new Date(
                              parseInt(sortedMisMatch[item].month, 10)
                            ).getMonth()
                          ] +
                            '-' +
                            new Date(
                              parseInt(sortedMisMatch[item].month, 10)
                            ).getFullYear()}
                        </td>

                        <td align="left">
                          <a
                            href={
                              'http://10.91.36.60:8181/browse/' +
                              sortedMisMatch[item].auditKey
                            }
                            target="_blank"
                          >
                            {' '}
                            {sortedMisMatch[item].auditKey}
                          </a>
                        </td>
                        <td align="left">{sortedMisMatch[item].auditType}</td>
                        <td align="left">
                          {sortedMisMatch[item].mismatchType}
                        </td>
                      </tr>
                    ))
                }
              </tbody>
            </table>
            {/* </TableScrollbar> */}
          </div>
        </div>
        <div className="container">
          <div className="py-4">
            <h3>HMD Details</h3>
            <div class="col-sm-3 col-md-3 pull-right">
              <link
                href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                rel="stylesheet"
              />
              {/* <div className="search-box-container search-box"> */}

              {/* </div> */}
            </div>
            {/* <TableScrollbar rows={4}> */}
            <table className="table w3-hoverable">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>WF Id/Skill Id</th>
                  <th>Detractor Type</th>
                </tr>
              </thead>
              <tbody>
                {
                  // profileAndPerfDataByTester.hmd && Object.keys(profileAndPerfDataByTester.hmd).map((item) => (

                  // profileAndPerfDataByTester.hmd[item].map((key) => (
                  // console.log(key)
                  sortedHMD &&
                    Object.keys(sortedHMD).map((item) => (
                      <tr align="center">
                        <td align="left">
                          {monthName[
                            new Date(
                              parseInt(sortedHMD[item].month, 10)
                            ).getMonth()
                          ] +
                            '-' +
                            new Date(
                              parseInt(sortedHMD[item].month, 10)
                            ).getFullYear()}
                        </td>
                        <td align="left">
                          <a
                            href={
                              new Date(
                                parseInt(sortedHMD[item].month, 10)
                              ).getFullYear() < '2022'
                                ? 'https://skillcert.aka.amazon.com/application/' +
                                  sortedHMD[item].appid +
                                  '/detail.html'
                                : 'https://optimus-prod.aka.amazon.com/skill/' +
                                  sortedHMD[item].skillid
                            }
                            target="_blank"
                          >
                            {' '}
                            {sortedHMD[item].appid}
                          </a>
                        </td>

                        <td align="left">{sortedHMD[item].detractor}</td>
                      </tr>
                    ))
                }
              </tbody>
            </table>
            {/* </TableScrollbar> */}
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(TesterPage)
