import React, { useEffect, useState } from 'react';
import Navbar from './components/layout/Navbar'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import "../node_modules/bootstrap/dist/css/bootstrap.css"
import "./style.css"
import "./SidebarStyles.css";
import TesterPage from './components/pages/TesterPage';
import MangerPage from './components/pages/ManagerPage';
import Amplify, { Auth, Hub } from 'aws-amplify';
import Homepage from "./components/pages/Home";
import UserNotFound from "./components/pages/UserNotFound";
import UserAccess from './components/Auth/UserAccess';
import AccessNotFound from "./components/pages/AccessNotFound";
import MyProfile from './components/pages/MyProfile';
import UserId from './components/Auth/UserId'
import ManagerPage from './components/pages/ManagerPage';




Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    // userPoolId: 'us-east-1_Q8d1lhsxp', //NASA
    userPoolId: 'us-east-1_xxWUK1Sor', //CopsHome


    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    // userPoolWebClientId: '5bsdlb9qbqcj6ogptv13c11ak5', //NASA-Aq
    // userPoolWebClientId: '7coqm9rnla4adqus6uvqn5e4om', //NASA-Dash
    userPoolWebClientId: '1d8gm62af8cf9r48ouce1qjpdf', //CopsHome_NoSecret



    // OPTIONAL - Hosted UI configuration
    oauth: {
      // domain: 'nasadash.auth.us-east-1.amazoncognito.com', //NASA
      domain: 'copshomefederate.auth.us-east-1.amazoncognito.com', //CopsHome
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      redirectSignOut: 'https://copsprofiler-react-static.s3.amazonaws.com/login/oauth2/code/cognito',
      responseType: 'code' // REFRESH token will only be generated when the responseType is code
    }
  }
});

// function App() {

//   const [user, setUser] = useState(null);
//   const currentUser = null;
//   let [userData, setUserData] = useState(null);

//   useEffect(() => {
//     getUser().then(userData => setUserData(userData));
//   }, []);

//   function getUser() {
//     return Auth.currentAuthenticatedUser()
//       // .then(userData => userData)
//       .then(userData => console.log("userinfoAppJs", userData))
//       .catch(() => Auth.federatedSignIn());
//   }
//   const accessLevel = UserAccess();
//   if (accessLevel === "unknown") {
//     return (
//       <div className="App">
//         <Router>
//           <div className="App">

//             {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"}/> */}
//             <Switch>
//               <Route exact path="/" component={UserNotFound} />
//               <Route exact path="/Home" component={UserNotFound} />
//               <Route exact path="/TesterPage" component={UserNotFound} />
//               <Route exact path="/ManagerPage" component={UserNotFound} />
//             </Switch>
//           </div>
//         </Router>
//       </div>
//     );
//   } else if (accessLevel === "Tester") {
//     return (
//       <div className="App">
//         <Router>
//           <div className="App">
//             <Navbar />
//             {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"}/> */}
//             <Switch>
//               <Route exact path="/" component={Homepage} />
//               <Route exact path="/Home" component={Homepage} />
//               <Route exact path="/TesterPage/:testerName" component={TesterPage} />
//               <Route exact path="/ManagerPage" component={AccessNotFound} />
//             </Switch>
//           </div>
//         </Router>
//       </div>
//     );
//   }
// }

function App() {

  const [user, setUser] = useState(null);
  const currentUser = UserId();
  let [userData, setUserData] = useState(null);

  useEffect(() => {
    getUser().then(userData => setUserData(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => console.log("userinfoAppJs", userData))
      .catch(() => Auth.federatedSignIn());
  }
  const accessLevel = UserAccess();
  if (accessLevel === "unknown") {
    return (
      <div className="App">
        <Router>
          <div className="App">

            {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"}/> */}
            <Switch>
              <Route exact path="/" component={UserNotFound} />
              <Route exact path="/Home" component={UserNotFound} />
              {/* <Route exact path="/TesterPage" component={TesterPage} /> */}
              <Route exact path="/ManagerPage" component={UserNotFound} />
              {/* <Route exact path="/Chart" component={Chart} /> */}
            </Switch>
          </div>
        </Router>
      </div>
    );
  } else if (accessLevel === "Tester") {
    return (
      <div className="App">
        <Router>
          <div className="App">
            <Navbar />
            {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"}/> */}
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route exact path="/Home" component={Homepage} />
              {/* <Route exact path="/ManagerPage" component={MangerPage} /> */}
              <Route exact path="/MyProfile" component={MyProfile} />

            </Switch>
          </div>
        </Router>
      </div>
    );
  }
  else {
    return (
      <div className="App">
        <Router>
          <div className="App">
            <Navbar />
            {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"}/> */}
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route exact path="/Home" component={Homepage} />
              <Route exact path="/ManagerPage" component={MangerPage} />
              <Route exact path="/TesterPage/:testerName" component={TesterPage} />
              {/* <Route exact path="/Chart/:testerName" component={Chart} /> */}
              {/* <Route exact path="/TesterProfile" component={TesterProfile} /> */}

            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;