import React, { useEffect, useState } from 'react';
const UserNotFound = () => {

  return (
    <div>
      <main>
        <h1>Sorry!,
      seems like you dont have access to ADECT Profiler <em>. . . Please reach out to WFM team for the access.</em>
        </h1>
      </main>
      <aside><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/Mirror.png" alt="404 Image" />
      </aside>
    </div>
  )
};

export default UserNotFound;