import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { NavLink } from "react-router-dom";
import UserName from '../Auth/UserName';
import * as FaIcons from "react-icons/fa";
import * as FcIcons from "react-icons/fc";
import UserAccess from '../Auth/UserAccess';
import UserId from '../Auth/UserId'


export default props => {
    const accessLevel = UserAccess();
    const currentUser = UserId();

    if (accessLevel === "unknown") {
        return (

            <Menu className="bm-menu "{...props}>
                <li className="nav-item "></li>
            </Menu>

        );
    }
    else if (accessLevel === "Admin") {
        return (
            <Menu className="bm-menu " {...props}>
                <li className="nav-item "></li>

                <li className="nav-item menuSpacing addNewStyling textSizeHeader"> <FaIcons.FaUserCircle /><UserName /></li>
                <li className="nav-item addNewStyling textSizeHeader greyOut"><UserAccess /></li>

                <li className="nav-item menuSeprartor"></li>

                <li className="nav-item menuSeprartor textSizeHeader">
                    <NavLink className="nav-link bm-item" exact to="/Home">
                        <FaIcons.FaUsers />    Home
              </NavLink>
                </li>

                <li className="nav-item menuSeprartor textSizeHeader">
                    <NavLink className="nav-link bm-item nav-item" exact to="/ManagerPage">
                        <FaIcons.FaBookOpen />   Manager Page
              </NavLink>

                </li>
                {/* <li className="nav-item menuSeprartor textSizeHeader">
                    <NavLink className="nav-link bm-item" exact to="/TesterPage">
                        <FaIcons.FaUsers />     Tester Page
              </NavLink>
                </li> */}
                {/* <li className="nav-item menuSeprartor textSizeHeader">
                    <NavLink className="nav-link bm-item" exact to="/TesterProfile">
                        <FaIcons.FaUsers />     My Profile
              </NavLink>
                </li> */}
                <br></br>

                <li className="nav-item   textSizeSemiHeader">
                    <a className="bm-item" href="https://tiny.amazon.com/c0uxzrr3" target='_blank'>  <FaIcons.FaBug />  Report an issue</a>
                </li>

                <li className="nav-item menuSpacing textSizeSemiHeader">
                    <a className="bm-item" href="" target='_blank'> <FaIcons.FaInfoCircle />    About Us </a>
                </li>
            </Menu >
        );
    }
    // else if (accessLevel === "Tester" || currentUser === 'poushalm') {
    else if (accessLevel === "Tester") {
        return (
            <Menu className="bm-menu " {...props}>
                <li className="nav-item "></li>

                <li className="nav-item menuSpacing addNewStyling textSizeHeader"> <FaIcons.FaUserCircle /> <UserName /></li>
                <li className="nav-item addNewStyling textSizeHeader greyOut"><UserAccess /></li>

                <li className="nav-item menuSeprartor"></li>

                <li className="nav-item menuSeprartor textSizeHeader">
                    <NavLink className="nav-link bm-item" exact to="/Home">
                        <FaIcons.FaUsers />    Home
              </NavLink>
                </li>

                {/* <li className="nav-item menuSeprartor textSizeHeader">
                    <NavLink className="nav-link bm-item" exact to="/TesterPage">
                        <FaIcons.FaUsers />     My Profile
              </NavLink>
                </li> */}

                <li className="nav-item menuSeprartor textSizeHeader">
                    <NavLink className="nav-link bm-item" exact to="/MyProfile">
                        <FaIcons.FaUsers />     My Profile
              </NavLink>
                </li>

                {/* <li className="nav-item menuSeprartor textSizeHeader">
                    <NavLink className="nav-link bm-item" exact to="/Chart">
                        <FaIcons.FaUsers />     Chart
              </NavLink>
                </li> */}
                <br></br>

                <li className="nav-item   textSizeSemiHeader">
                    <a className="bm-item" href="https://tiny.amazon.com/c0uxzrr3" target='_blank'>  <FaIcons.FaBug />  Report an issue</a>
                </li>

                <li className="nav-item menuSpacing textSizeSemiHeader">
                    <a className="bm-item" href="" target='_blank'> <FaIcons.FaInfoCircle />    About Us </a>
                </li>
            </Menu>
        );
    }
};