import React from 'react'
import { withRouter, Redirect } from 'react-router'
import axios from 'axios'
import { Tab, TabPanel, Tabs, TabList } from 'react-web-tabs'
import 'react-web-tabs/dist/react-web-tabs.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Link } from 'react-router-dom'
import { Bar } from 'react-chartjs-2'
import UserId from '../Auth/UserId'
import LoadingScreen from './LoadingScreen'

var monthName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

var userId

class ManagerPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teamPerformanceData: {},
      reportingTesterDetails: {},
      name: '',
      startDate: new Date(),
      endDate: new Date(),
      teamAvg: {},
      productivity: {},
      quality: {},
      pkt: {},
      teamHmd: [],
      teamMismatches: [],
      tableData: [],
      m: '',
      lastUpdatedMonth: '',
      testerAverageData: {},
      sortType: 'asc',
      newMismatch: {},
      chartData: {},
      defaultStart: '',
      defaultEnd: '',
      availableMonthRange: {},
    }
  }

  componentDidMount() {
    userId = UserId()
    const { startDate, endDate } = this.state

    axios
      .get(
        process.env.REACT_APP_PROFILER_BACKEND + 'TeamPerformanceData/' + userId
      )
      .then((res) => {
        let res_start_date = res.data.startDate
          ? res.data.startDate
          : Date.now()
        let res_end_date = res.data.endDate ? res.data.endDate : Date.now()

        let chart = {
          labels:
            res.data.testersAverage && Object.keys(res.data.testersAverage),
          datasets: [
            {
              label: 'pkt',
              data:
                res.data.testersAverage &&
                Object.keys(res.data.testersAverage).map((item) => {
                  return res.data.testersAverage[item]
                    ? res.data.testersAverage[item].avgPkt.toFixed(2)
                    : 0
                }),
              backgroundColor: 'rgba(255,99,132,0.6)',
            },
            {
              label: 'productivity',
              data:
                res.data.testersAverage &&
                Object.keys(res.data.testersAverage).map((item) => {
                  return res.data.testersAverage[item]
                    ? res.data.testersAverage[item].avgProductivity.toFixed(2)
                    : 0
                }),
              backgroundColor: 'rgba(54,162,235,0.6)',
            },
            {
              label: 'quality',
              data:
                res.data.testersAverage &&
                Object.keys(res.data.testersAverage).map((item) => {
                  return res.data.testersAverage[item].avgQuality
                    ? res.data.testersAverage[item].avgQuality.toFixed(2)
                    : 0
                }),
              backgroundColor: 'rgba(75,192,192,0.4)',
            },
          ],
        }
        let quality_arr = []
        res.data.productivity &&
          Object.keys(res.data.productivity).forEach((item) => {
            let quality_temp = Object.keys(res.data.productivity[item]).map(
              (subItem) => {
                return {
                  month: item,
                  tester: subItem,
                  quality_percentage: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].quality_percentage
                    : 0,
                  productivity: res.data.productivity[item][subItem]
                    ? res.data.productivity[item][subItem]
                    : 0,
                  pkt: res.data.pkt[item][subItem]
                    ? res.data.pkt[item][subItem]
                    : null,
                  sev1: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].no_of_sev1
                    : '-',
                  sev2: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].no_of_sev2
                    : '-',
                  sev3: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].no_of_sev3
                    : '-',
                  consistencyAudits: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].consistencyAudits
                    : '-',
                  xRayAudits: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].xRayAudits
                    : '-',
                  total_samples: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].total_samples
                    : '-',
                }
              }
            )
            quality_arr = quality_arr.concat(quality_temp)
          })
        let teamMismatches_arr = []
        res.data.teamMismatches &&
          Object.keys(res.data.teamMismatches).forEach((item) => {
            let teamMismatches_temp = Object.keys(
              res.data.teamMismatches[item]
            ).map((subItem) => {
              return {
                month: item,
                tester: res.data.teamMismatches[item][subItem].testerName,
                auditKey: res.data.teamMismatches[item][subItem].auditKey,
                auditType: res.data.teamMismatches[item][subItem].auditType,
                mismatchType:
                  res.data.teamMismatches[item][subItem].mismatchType,
              }
            })
            teamMismatches_arr = teamMismatches_arr.concat(teamMismatches_temp)
          })
        let teamHmd_arr = []
        res.data.teamHmd &&
          Object.keys(res.data.teamHmd).forEach((item) => {
            let teamHmd_temp = Object.keys(res.data.teamHmd[item]).map(
              (subItem) => {
                return {
                  month: item,
                  tester: res.data.teamHmd[item][subItem].testerName,
                  detractor: res.data.teamHmd[item][subItem].detractor,
                  appid: res.data.teamHmd[item][subItem].appid.split('|')[0],
                  skillId: res.data.teamHmd[item][subItem].appid.split('|')[1],
                }
              }
            )
            teamHmd_arr = teamHmd_arr.concat(teamHmd_temp)
          })
        this.setState({
          teamPerformanceData: res.data ? res.data : null,
          name: res.data.name ? res.data.name : null,
          teamAvg: res.data.teamaverage ? res.data.teamaverage : null,
          productivity: res.data.productivity ? res.data.productivity : null,
          quality: res.data.quality ? res.data.quality : null,
          pkt: res.data.pkt ? res.data.pkt : null,
          teamHmd: teamHmd_arr ? teamHmd_arr : null,
          teamMismatches: teamMismatches_arr ? teamMismatches_arr : null,
          testerAverageData: res.data.testersAverage
            ? res.data.testersAverage
            : null,
          tableData: quality_arr ? quality_arr : null,
          chartData: chart ? chart : null,
          startDate: new Date(res_start_date),
          endDate: new Date(res_end_date),
        })
        this.getLastUpdatedMonth()
        this.getReportingTestersDeatils()
      })
  }

  getReportingTestersDeatils() {
    // const userId = UserId();
    axios
      .get(
        process.env.REACT_APP_PROFILER_BACKEND +
          'ReportingTestersDeatils/' +
          userId
      )
      .then((res) => {
        this.setState({
          reportingTesterDetails: res.data,
        })
      })
  }

  getLastUpdatedMonth() {
    const { startDate, endDate } = this.state
    console.log(
      'Start Date End date in getLastUpdatedMonth ',
      startDate,
      endDate
    )
    axios
      .get(
        process.env.REACT_APP_PROFILER_BACKEND + 'availableDateRange/' + userId
      )
      .then((res) => {
        let min, max
        if (Object.keys(res.data).length > 0) {
          max = Math.max.apply(null, Object.keys(res.data))
          min = Math.min.apply(null, Object.keys(res.data))
        } else {
          min = new Date(startDate)
          max = new Date(endDate)
        }
        this.setState({
          lastUpdatedMonth: res.data,
          startDate: new Date(min),
          endDate: new Date(max),
          availableMonthRange: res.data,
        })
      })
  }

  handleDateChange = (e) => {
    const { startDate, endDate, availableMonthRange } = this.state
    console.log(
      'selected month',
      monthName[e.getMonth()] + '-' + e.getFullYear()
    )
    console.log('avaiable month range', availableMonthRange)
    let monthRange = []
    monthRange = Object.values(availableMonthRange)
    let selectedStartDate = monthName[e.getMonth()] + '-' + e.getFullYear()

    let min, max
    if (Object.keys(availableMonthRange).length > 0) {
      max =
        monthName[
          new Date(
            Math.max.apply(null, Object.keys(availableMonthRange))
          ).getMonth()
        ] +
        '-' +
        new Date(
          Math.max.apply(null, Object.keys(availableMonthRange))
        ).getFullYear()
      min =
        monthName[
          new Date(
            Math.min.apply(null, Object.keys(availableMonthRange))
          ).getMonth()
        ] +
        '-' +
        new Date(
          Math.min.apply(null, Object.keys(availableMonthRange))
        ).getFullYear()
    } else {
      max = Date.now()
      min = Date.now()
    }
    console.log('Maximum month', max)
    console.log('Minimum month', min)
    let newStartDate, newEndDate
    if (monthRange.includes(selectedStartDate)) {
      newStartDate = selectedStartDate
      newEndDate = monthName[endDate.getMonth()] + '-' + endDate.getFullYear()
    } else {
      newEndDate = max
      newStartDate = min
    }

    console.log('Date Change in Onchnage', newStartDate)
    axios
      .get(
        process.env.REACT_APP_PROFILER_BACKEND +
          'TeamPerformanceData/' +
          userId +
          '/' +
          newStartDate +
          '/' +
          newEndDate
      )
      .then((res) => {
        let chart = {
          labels:
            res.data.testersAverage && Object.keys(res.data.testersAverage),
          datasets: [
            {
              label: 'pkt',
              data:
                res.data.testersAverage &&
                Object.keys(res.data.testersAverage).map((item) => {
                  return res.data.testersAverage[item]
                    ? res.data.testersAverage[item].avgPkt.toFixed(2)
                    : 0
                }),
              backgroundColor: 'rgba(255,99,132,0.6)',
            },
            {
              label: 'productivity',
              data:
                res.data.testersAverage &&
                Object.keys(res.data.testersAverage).map((item) => {
                  return res.data.testersAverage[item]
                    ? res.data.testersAverage[item].avgProductivity.toFixed(2)
                    : 0
                }),
              backgroundColor: 'rgba(54,162,235,0.6)',
            },
            {
              label: 'quality',
              data:
                res.data.testersAverage &&
                Object.keys(res.data.testersAverage).map((item) => {
                  return res.data.testersAverage[item]
                    ? res.data.testersAverage[item].avgQuality.toFixed(2)
                    : 0
                }),
              backgroundColor: 'rgba(75,192,192,0.4)',
            },
          ],
        }
        let quality_arr = []
        res.data.productivity &&
          Object.keys(res.data.productivity).forEach((item) => {
            let quality_temp = Object.keys(res.data.productivity[item]).map(
              (subItem) => {
                return {
                  month: item,
                  tester: subItem,
                  quality_percentage: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].quality_percentage
                    : 0,
                  productivity: res.data.productivity[item][subItem]
                    ? res.data.productivity[item][subItem]
                    : 0,
                  pkt: res.data.pkt[item][subItem]
                    ? res.data.pkt[item][subItem]
                    : null,
                  sev1: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].no_of_sev1
                    : '-',
                  sev2: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].no_of_sev2
                    : '-',
                  sev3: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].no_of_sev3
                    : '-',
                  consistencyAudits: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].consistencyAudits
                    : '-',
                  xRayAudits: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].xRayAudits
                    : '-',
                  total_samples: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].total_samples
                    : '-',
                }
              }
            )
            quality_arr = quality_arr.concat(quality_temp)
          })
        let teamMismatches_arr = []
        res.data.teamMismatches &&
          Object.keys(res.data.teamMismatches).forEach((item) => {
            let teamMismatches_temp = Object.keys(
              res.data.teamMismatches[item]
            ).map((subItem) => {
              return {
                month: item,
                tester: res.data.teamMismatches[item][subItem].testerName,
                auditKey: res.data.teamMismatches[item][subItem].auditKey,
                auditType: res.data.teamMismatches[item][subItem].auditType,
                mismatchType:
                  res.data.teamMismatches[item][subItem].mismatchType,
              }
            })
            teamMismatches_arr = teamMismatches_arr.concat(teamMismatches_temp)
          })
        let teamHmd_arr = []
        res.data.teamHmd &&
          Object.keys(res.data.teamHmd).forEach((item) => {
            let teamHmd_temp = Object.keys(res.data.teamHmd[item]).map(
              (subItem) => {
                return {
                  month: item,
                  tester: res.data.teamHmd[item][subItem].testerName,
                  detractor: res.data.teamHmd[item][subItem].detractor,
                  appid: res.data.teamHmd[item][subItem].appid.split('|')[0],
                  skillId: res.data.teamHmd[item][subItem].appid.split('|')[1],
                }
              }
            )
            teamHmd_arr = teamHmd_arr.concat(teamHmd_temp)
          })
        this.setState({
          teamPerformanceData: res.data,
          name: res.data.name,
          teamAvg: res.data.teamaverage,
          productivity: res.data.productivity,
          quality: res.data.quality,
          pkt: res.data.pkt,
          teamHmd: teamHmd_arr,
          teamMismatches: teamMismatches_arr,
          tableData: quality_arr,
          chartData: chart,
          testerAverageData: res.data.testersAverage
            ? res.data.testersAverage
            : null,
          startDate: new Date(newStartDate),
          endDate: new Date(newEndDate),
        })
      })
  }

  onClick = () => {
    const { startDate, endDate } = this.state
    console.log('Inside OnSubmit:', startDate, endDate)
  }

  handleEndDateChange = (e) => {
    const { startDate, endDate, availableMonthRange } = this.state
    console.log(
      'selected End Date',
      monthName[e.getMonth()] + '-' + e.getFullYear()
    )
    console.log('avaiable month range', availableMonthRange)
    console.log('startDate', startDate)
    let monthRange = []
    monthRange = Object.values(availableMonthRange)
    let selectedEndDate = monthName[e.getMonth()] + '-' + e.getFullYear()

    let min, max
    if (Object.keys(availableMonthRange).length > 0) {
      max =
        monthName[
          new Date(
            Math.max.apply(null, Object.keys(availableMonthRange))
          ).getMonth()
        ] +
        '-' +
        new Date(
          Math.max.apply(null, Object.keys(availableMonthRange))
        ).getFullYear()
      min =
        monthName[
          new Date(
            Math.min.apply(null, Object.keys(availableMonthRange))
          ).getMonth()
        ] +
        '-' +
        new Date(
          Math.min.apply(null, Object.keys(availableMonthRange))
        ).getFullYear()
    } else {
      max = Date.now()
      min = Date.now()
    }

    console.log('Maximum month', max)
    console.log('Minimum month', min)
    let newStartDate, newEndDate

    if (monthRange.includes(selectedEndDate)) {
      newEndDate = selectedEndDate
      newStartDate =
        monthName[startDate.getMonth()] + '-' + startDate.getFullYear()
    } else {
      newEndDate = max
      newStartDate = min
    }
    console.log('Start Date inside End Date onChnage', startDate)
    console.log('New Start Date and New End Date', newStartDate, newEndDate)
    console.log(
      process.env.REACT_APP_PROFILER_BACKEND +
        'TeamPerformanceData/' +
        userId +
        '/' +
        newStartDate +
        '/' +
        newEndDate
    )
    axios
      .get(
        process.env.REACT_APP_PROFILER_BACKEND +
          'TeamPerformanceData/' +
          userId +
          '/' +
          newStartDate +
          '/' +
          newEndDate
      )
      .then((res) => {
        let chart = {
          labels:
            res.data.testersAverage && Object.keys(res.data.testersAverage),
          datasets: [
            {
              label: 'pkt',
              data:
                res.data.testersAverage &&
                Object.keys(res.data.testersAverage).map((item) => {
                  return res.data.testersAverage[item]
                    ? res.data.testersAverage[item].avgPkt.toFixed(2)
                    : 0
                }),
              backgroundColor: 'rgba(255,99,132,0.6)',
            },
            {
              label: 'productivity',
              data:
                res.data.testersAverage &&
                Object.keys(res.data.testersAverage).map((item) => {
                  return res.data.testersAverage[item]
                    ? res.data.testersAverage[item].avgProductivity.toFixed(2)
                    : 0
                }),
              backgroundColor: 'rgba(54,162,235,0.6)',
            },
            {
              label: 'quality',
              data:
                res.data.testersAverage &&
                Object.keys(res.data.testersAverage).map((item) => {
                  return res.data.testersAverage[item]
                    ? res.data.testersAverage[item].avgQuality.toFixed(2)
                    : 0
                }),
              backgroundColor: 'rgba(75,192,192,0.4)',
            },
          ],
        }
        let quality_arr = []
        res.data.productivity &&
          Object.keys(res.data.productivity).forEach((item) => {
            let quality_temp = Object.keys(res.data.productivity[item]).map(
              (subItem) => {
                return {
                  month: item,
                  tester: subItem,
                  quality_percentage: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].quality_percentage
                    : 0,
                  productivity: res.data.productivity[item][subItem]
                    ? res.data.productivity[item][subItem]
                    : 0,
                  pkt: res.data.pkt[item][subItem]
                    ? res.data.pkt[item][subItem]
                    : null,
                  sev1: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].no_of_sev1
                    : '-',
                  sev2: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].no_of_sev2
                    : '-',
                  sev3: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].no_of_sev3
                    : '-',
                  consistencyAudits: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].consistencyAudits
                    : '-',
                  xRayAudits: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].xRayAudits
                    : '-',
                  total_samples: res.data.quality[item][subItem]
                    ? res.data.quality[item][subItem].total_samples
                    : '-',
                }
              }
            )
            quality_arr = quality_arr.concat(quality_temp)
          })
        let teamMismatches_arr = []
        res.data.teamMismatches &&
          Object.keys(res.data.teamMismatches).forEach((item) => {
            let teamMismatches_temp = Object.keys(
              res.data.teamMismatches[item]
            ).map((subItem) => {
              return {
                month: item,
                tester: res.data.teamMismatches[item][subItem].testerName,
                auditKey: res.data.teamMismatches[item][subItem].auditKey,
                auditType: res.data.teamMismatches[item][subItem].auditType,
                mismatchType:
                  res.data.teamMismatches[item][subItem].mismatchType,
              }
            })
            teamMismatches_arr = teamMismatches_arr.concat(teamMismatches_temp)
          })
        let teamHmd_arr = []
        res.data.teamHmd &&
          Object.keys(res.data.teamHmd).forEach((item) => {
            let teamHmd_temp = Object.keys(res.data.teamHmd[item]).map(
              (subItem) => {
                return {
                  month: item,
                  tester: res.data.teamHmd[item][subItem].testerName,
                  detractor: res.data.teamHmd[item][subItem].detractor,
                  appid: res.data.teamHmd[item][subItem].appid.split('|')[0],
                  skillId: res.data.teamHmd[item][subItem].appid.split('|')[1],
                }
              }
            )
            teamHmd_arr = teamHmd_arr.concat(teamHmd_temp)
          })
        this.setState({
          teamPerformanceData: res.data,
          name: res.data.name,
          teamAvg: res.data.teamaverage,
          productivity: res.data.productivity,
          quality: res.data.quality,
          pkt: res.data.pkt,
          teamHmd: teamHmd_arr,
          teamMismatches: teamMismatches_arr,
          tableData: quality_arr,
          chartData: chart,
          testerAverageData: res.data.testersAverage
            ? res.data.testersAverage
            : null,
          startDate: new Date(newStartDate),
          endDate: new Date(newEndDate),
        })
      })
  }

  render() {
    const {
      productivity,
      teamPerformanceData,
      quality,
      tableData,
      teamAvg,
      teamMismatches,
      teamHmd,
      m,
      startDate,
      endDate,
      lastUpdatedMonth,
      testerAverageData,
      sortType,
      reportingTesterDetails,
      chartData,
      defaultEnd,
      defaultStart,
    } = this.state
    const testerList =
      this.state.productivity &&
      productivity[Object.keys(this.state.productivity)[0]]
    console.log('TesterList', testerList)
    console.log('TeamPerfData', teamPerformanceData)
    console.log('Productivity', productivity)
    console.log('Quality', quality)
    console.log('table', tableData)
    console.log('TeamMisMatches', teamMismatches)
    console.log('start date', startDate)
    console.log('End Date', endDate)
    console.log('Tester Average Data', testerAverageData)
    console.log('TeamHMD', teamHmd)
    console.log('Reporting Details', reportingTesterDetails)
    console.log('Chart Data', chartData)
    console.log('MIN and MAX', defaultStart, defaultEnd)
    console.log('Start Date New  ', startDate, endDate)

    const sorted = tableData.sort((a, b) => {
      const isReserved = sortType === 'asc' ? 1 : -1
      return isReserved * a.month.localeCompare(b.month)
    })

    console.log('sorted Table Data', sorted)
    console.log('Before Soted TeamMisMatch', teamMismatches)

    const sortedTeamMisMatches = teamMismatches.sort((a, b) => {
      const isReserved = sortType === 'asc' ? 1 : -1
      return isReserved * a.month.localeCompare(b.month)
    })
    const sortedTeamHmd = teamHmd.sort((a, b) => {
      const isReserved = sortType === 'asc' ? 1 : -1
      return isReserved * a.month.localeCompare(b.month)
    })

    if (userId == 'notLoggedInDa') {
      // window.location.reload();
      return (
        <div>
          <LoadingScreen />
          {<userId />}
        </div>
      )
    } else {
      return (
        <div className="container">
          <div className="py-4">
            <Tabs>
              <TabList>
                <div className="name">
                  <h9 className="headerInline addNewStyling textSizeHeader">
                    {' '}
                    Manager Page
                  </h9>
                  <div className="button headerInline headerMargin textSizeHeader">
                    <button className="link-align disable-styling button-styling">
                      Start-Date
                      <br></br>
                      <DatePicker
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        onChange={this.handleDateChange}
                        selected={startDate}
                        maxDate={new Date()}
                        minDate={new Date(2020, 0, 1)}
                      ></DatePicker>
                    </button>
                    <button className="link-align disable-styling button-styling">
                      End-Date
                      <br></br>
                      <DatePicker
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        onChange={this.handleEndDateChange}
                        selected={endDate}
                        minDate={new Date(2020, 0, 1)}
                        maxDate={new Date()}
                      ></DatePicker>
                    </button>
                  </div>
                </div>
                <br></br>
                <Tab
                  tabFor="horizental-tab-one"
                  className="addNewStyling textSizeHeader"
                >
                  Home
                </Tab>
                <Tab
                  tabFor="horizental-tab-two"
                  className="addNewStyling textSizeHeader"
                >
                  Team Performance
                </Tab>
                <Tab
                  tabFor="horizental-tab-three"
                  className="addNewStyling textSizeHeader"
                >
                  Quality Trend
                </Tab>
                <Tab
                  tabFor="horizental-tab-four"
                  className="addNewStyling textSizeHeader"
                >
                  HMD
                </Tab>
              </TabList>
              <TabPanel tabId="horizental-tab-one">
                <br></br>
                <h3>Tester List</h3>
                <table className="table w3-hoverable">
                  <thead>
                    <tr>
                      <th>Tester Name</th>
                      <th>Alias</th>
                      <th>Current Role</th>
                      <th>Tenure</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportingTesterDetails &&
                      Object.keys(reportingTesterDetails).map((item) => (
                        <tr align="left">
                          <td>{reportingTesterDetails[item].fullName}</td>
                          <td>{reportingTesterDetails[item].name}</td>
                          <td>{reportingTesterDetails[item].currentRole}</td>
                          <td>{reportingTesterDetails[item].totalTenure}</td>
                          <td>
                            <Link
                              className="link-align view-styling"
                              to={`/TesterPage/${reportingTesterDetails[item].name}`}
                            >
                              View Profile
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </TabPanel>
              <TabPanel tabId="horizental-tab-two">
                <Tabs>
                  <TabList>
                    <Tab
                      tabFor="horizental-tab-one"
                      className="addNewStyling textSizeHeader"
                    >
                      Team Performance Data
                    </Tab>
                    <Tab
                      tabFor="horizental-tab-two"
                      className="addNewStyling textSizeHeader"
                    >
                      Tester Average Data
                    </Tab>
                    <Tab
                      tabFor="horizental-tab-three"
                      className="addNewStyling textSizeHeader"
                    >
                      Team Average Data
                    </Tab>
                  </TabList>
                  <TabPanel tabId="horizental-tab-one">
                    <div>
                      <div className="chart">
                        <Bar
                          data={chartData}
                          options={{
                            scales: {
                              xAxes: [
                                {
                                  ticks: {
                                    display: false,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  ticks: {
                                    beginAtZero: true,
                                  },
                                },
                              ],
                            },
                            title: {
                              display: true,
                              text:
                                'Performance Data For ' +
                                monthName[startDate.getMonth()] +
                                '-' +
                                startDate.getFullYear() +
                                ' to ' +
                                monthName[endDate.getMonth()] +
                                '-' +
                                endDate.getFullYear(),
                              fontSize: 25,
                            },
                            legend: {
                              display: true,
                              position: 'right',
                            },
                          }}
                        />
                      </div>
                      <br></br>
                      <h3>Performance&nbsp;&nbsp;</h3>
                      <table className="table w3-hoverable">
                        <thead>
                          <tr>
                            <th>Tester</th>
                            <th>Month</th>
                            <th>Quality</th>
                            <th>PKT Score</th>
                            <th>Productivity</th>
                          </tr>
                        </thead>
                        <tbody className="scrollable">
                          {sorted &&
                            Object.keys(sorted).map((item) => (
                              <tr align="left">
                                <td>{sorted[item].tester}</td>
                                <td>
                                  {monthName[
                                    new Date(
                                      parseInt(sorted[item].month, 10)
                                    ).getMonth()
                                  ] +
                                    '-' +
                                    new Date(
                                      parseInt(sorted[item].month, 10)
                                    ).getFullYear()}
                                </td>
                                <td align="left">
                                  {sorted[item].quality_percentage
                                    ? sorted[item].quality_percentage.toFixed(2)
                                    : '-'}
                                </td>
                                <td>
                                  {sorted[item].pkt ? sorted[item].pkt : '-'}
                                </td>
                                <td>
                                  {sorted[item].productivity
                                    ? sorted[item].productivity.toFixed(2)
                                    : '-'}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </TabPanel>
                  <TabPanel tabId="horizental-tab-two">
                    <div>
                      <table className="table w3-hoverable">
                        <thead>
                          <tr>
                            <th>Tester</th>
                            <th>Tester Avg-Prod</th>
                            <th>Tester Avg-quality</th>
                            <th>Tester Avg-PKT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {testerAverageData &&
                            Object.keys(testerAverageData).map((item) => (
                              <tr align="left">
                                <td>{item}</td>
                                <td>
                                  {testerAverageData
                                    ? testerAverageData[
                                        item
                                      ].avgProductivity.toFixed(2)
                                    : null}
                                </td>
                                <td>
                                  {testerAverageData
                                    ? testerAverageData[
                                        item
                                      ].avgQuality.toFixed(2)
                                    : null}
                                </td>
                                <td>
                                  {testerAverageData
                                    ? testerAverageData[item].avgPkt.toFixed(2)
                                    : null}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </TabPanel>
                  <TabPanel tabId="horizental-tab-three">
                    <div>
                      <table className="table w3-hoverable">
                        <thead>
                          <tr align="left">
                            <th>Month</th>
                            <th>Avg-Prod</th>
                            <th>Avg-quality</th>
                            <th>Avg-PKT</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr align="left">
                            <td>
                              {monthName[startDate.getMonth()] +
                                '-' +
                                startDate.getFullYear() +
                                ' to ' +
                                monthName[endDate.getMonth()] +
                                '-' +
                                endDate.getFullYear()}
                            </td>
                            <td>
                              {teamAvg
                                ? parseFloat(
                                    teamAvg['avgProductivity']
                                  ).toFixed(2)
                                : null}
                            </td>
                            <td>
                              {teamAvg
                                ? parseFloat(teamAvg['avgQuality']).toFixed(2)
                                : null}
                            </td>
                            <td>
                              {teamAvg
                                ? parseFloat(teamAvg['avgPkt']).toFixed(2)
                                : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </TabPanel>
                </Tabs>
              </TabPanel>
              <TabPanel tabId="horizental-tab-three">
                <div>
                  <br></br>
                  <h3>Audits Breakdown</h3>
                  <table className="table w3-hoverable">
                    <thead>
                      <tr>
                        <th>Tester</th>
                        <th>Month</th>
                        <th>Samples</th>
                        <th>Sev1</th>
                        <th>Sev2</th>
                        <th>Sev3</th>
                        <th>#xray</th>
                        <th>Consistency</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        Object.keys(tableData).map((item) => (
                          <tr align="left">
                            <td>{tableData[item].tester}</td>
                            <td>
                              {monthName[
                                new Date(
                                  parseInt(tableData[item].month, 10)
                                ).getMonth()
                              ] +
                                '-' +
                                new Date(
                                  parseInt(tableData[item].month, 10)
                                ).getFullYear()}
                            </td>
                            <td align="left">
                              {tableData[item].total_samples}
                            </td>
                            <td>{tableData[item].sev1}</td>
                            <td>{tableData[item].sev2}</td>
                            <td>{tableData[item].sev3}</td>
                            <td>{tableData[item].xRayAudits}</td>
                            <td>{tableData[item].consistencyAudits}</td>
                            <td>
                              {tableData[item].quality_percentage
                                ? tableData[item].quality_percentage.toFixed(2)
                                : '-'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div>
                  <h3>Mismatch Details</h3>
                  <table className="table w3-hoverable">
                    <thead>
                      <tr>
                        <th>Tester</th>
                        <th>Month</th>
                        <th>Audit Key</th>
                        <th>Audit Type</th>
                        <th>Mismatch Key</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedTeamMisMatches &&
                        Object.keys(sortedTeamMisMatches).map((item) => (
                          <tr align="left">
                            <td>{sortedTeamMisMatches[item].tester}</td>
                            <td>
                              {monthName[
                                new Date(
                                  parseInt(sortedTeamMisMatches[item].month, 10)
                                ).getMonth()
                              ] +
                                '-' +
                                new Date(
                                  parseInt(teamMismatches[item].month, 10)
                                ).getFullYear()}
                            </td>
                            <td>
                              <a
                                href={
                                  'http://10.91.36.60:8181/browse/' +
                                  sortedTeamMisMatches[item].auditKey
                                }
                                target="_blank"
                              >
                                {sortedTeamMisMatches[item].auditKey}
                              </a>
                            </td>
                            <td>{sortedTeamMisMatches[item].auditType}</td>
                            <td>{sortedTeamMisMatches[item].mismatchType}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel tabId="horizental-tab-four">
                <div>
                  <br></br>
                  <h3>HMD Details</h3>
                  <table className="table w3-hoverable breaklines">
                    <thead>
                      <tr>
                        <th>Tester</th>
                        <th>Month</th>
                        <th>WF Id/Skill Id</th>
                        <th>Detractor Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedTeamHmd &&
                        Object.keys(sortedTeamHmd).map((item) => (
                          <tr align="left">
                            <td>{sortedTeamHmd[item].tester}</td>
                            <td>
                              {monthName[
                                new Date(
                                  parseInt(sortedTeamHmd[item].month, 10)
                                ).getMonth()
                              ] +
                                '-' +
                                new Date(
                                  parseInt(sortedTeamHmd[item].month, 10)
                                ).getFullYear()}
                            </td>
                            <td>
                              <a
                                href={
                                  new Date(
                                    parseInt(sortedTeamHmd[item].month, 10)
                                  ).getFullYear() < '2022'
                                    ? 'https://skillcert.aka.amazon.com/application/' +
                                      sortedTeamHmd[item].appid +
                                      '/detail.html'
                                    : ' https://optimus-prod.aka.amazon.com/skill/' +
                                      sortedTeamHmd[item].skillId
                                }
                                target="_blank"
                              >
                                {sortedTeamHmd[item].appid}
                              </a>
                            </td>
                            <td>{sortedTeamHmd[item].detractor}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      )
    }
  }
}
export default withRouter(ManagerPage)
